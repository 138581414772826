import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { User } from '../models/User'
import type { RootState } from '../Store'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

type AuthState = {
  token: string | null
  authenticated: boolean
}

const persistConfig = {
  key: 'root',
  storage,
}

const slice = createSlice({
  name: 'auth',
  initialState: { token: null, authenticated: false } as AuthState,
  reducers: {
    setBearerToken: (
      state,
      {
        payload: { token },
      }: PayloadAction<{ token: string }>,
    ) => {
      state.token = token
      state.authenticated = true
    },
    logout: (state) => {
      state.token = null
      state.authenticated = false
    },
  },
})

export const { setBearerToken, logout } = slice.actions

export default persistReducer(persistConfig, slice.reducer)

export const selectBearerToken = (state: RootState) => state.auth.token
export const selectIsAuthenticated = (state: RootState) => state.auth.authenticated
