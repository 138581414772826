import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HighlightedCard from './HighlightedCard';
import SessionsChart from './SessionsChart';
import StatCard, { StatCardProps } from './StatCard';
import { useGetExpensesQuery, useUpdateExpenseMutation, useCreateExpenseMutation } from '../services/Api'
import { type Expense, columns as expenseColumns, defaultValue as defaultExpense } from '../models/Expense'
import Table from './Table'
import Input from '@mui/material/Input';
import CreateButton from './CreateButton';

const createFormFields = [
  {
    label: 'Name',
    name: 'name',
    type: 'name',
    size: { xs: 12, md: 6 },
  },
  {
    label: 'Amount',
    name: 'amount',
    type: 'number',
    size: { xs: 12, md: 6 },
  },
  {
    label: 'Starts At',
    name: 'starts_at',
    type: 'date',
    size: { xs: 12, md: 6 },
  },
  {
    label: 'Ends At',
    name: 'ends_at',
    type: 'date',
    size: { xs: 12, md: 6 },
  },
  {
    label: 'Recurrence',
    name: 'recurrence',
    type: 'select',
    size: { xs: 12 },
    items: [
      { value: 'one_off', label: 'One Off' },
      { value: 'monthly', label: 'Monthly' },
    ],
  },
]

export default function ExpensesPage() {
  const { data, error, isLoading } = useGetExpensesQuery()
  const [createExpense, result] = useCreateExpenseMutation()
  const [updateExpense, updateResult] = useUpdateExpenseMutation()

  return isLoading ? (<><span>Loading</span></>) : (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Expenses
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        <Grid size={{ sm: 12 }}>
          <Table onEdit={(row) => updateExpense(row)} columns={expenseColumns} data={data || []}/>

        </Grid>
        <Grid alignItems="right" size={{ sm: 12 }}>
          <CreateButton name="Expense" create={createExpense} defaultModel={defaultExpense} createFormFields={createFormFields} />
        </Grid>
      </Grid>
    </Box>
  );
}
