import * as React from 'react';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import Page from './components/Page';
import {
  createBrowserRouter,
} from "react-router-dom";

export interface MainListItem {
  text: string,
  icon: JSX.Element,
  path: string,
}

export interface SecondaryListItem {
  text: string,
  icon: JSX.Element,
}

export const mainListItems: MainListItem[] = [
  { text: 'Home', icon: <HomeRoundedIcon />, path: '/' },
  { text: 'Expenses', icon: <AnalyticsRoundedIcon />, path: '/expenses' },
  { text: 'Savings', icon: <AnalyticsRoundedIcon />, path: '/savings' },
];

export const secondaryListItems: SecondaryListItem[] = [
  { text: 'Settings', icon: <SettingsRoundedIcon /> },
  { text: 'About', icon: <InfoRoundedIcon /> },
  { text: 'Feedback', icon: <HelpRoundedIcon /> },
];

export const router = createBrowserRouter(
  mainListItems.map((item: MainListItem, index: number) => ({
    path: item.path,
    element: (<Page selectedPage={item.text}/>)
  }))
)
