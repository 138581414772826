import * as React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleSignInMutation, GOOGLE_CLIENT_ID } from '../services/Api';
import { useDispatch } from 'react-redux'
import { setBearerToken, selectBearerToken } from '../features/authSlice'
import { GoogleOAuthProvider } from '@react-oauth/google';

export default function LoginPage() {
  const [googleSignIn, {isLoading, data: googleSignInResult, isSuccess}] = useGoogleSignInMutation()
  const dispatch = useDispatch()

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <GoogleLogin
        onSuccess={async (credentialResponse) => {
          const response = await googleSignIn({
            credential: credentialResponse.credential || ""
          })
          if(response.data) {
            dispatch(setBearerToken({ token: response.data.token }))
          }
        }}
        onError={() => {
          console.log('Login Failed');
        }}
      />
    </GoogleOAuthProvider>
  )
}
