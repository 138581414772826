import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid2';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

function FinsimInput(
  {formState, name, handleChange, handleDateChange, type, items}:any
) {
  switch(type) {
    case 'date':
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker value={formState[name]} onChange={(newDate) => handleDateChange(name, newDate)} />
        </LocalizationProvider>
      )
    case 'select':
      return (
        <Select
          id={name}
          name={name}
          value={formState[name]}
          onChange={handleChange}
          type={name}
          defaultValue="one_off" // TODO: Fix me
          required
          size="small"
        >
          { items.map((item: any) => (<MenuItem key={item.label} value={item.value}>{item.label}</MenuItem> )
          )}
        </Select>
      )
    default:
      return (
        <OutlinedInput
          id={name}
          name={name}
          value={formState[name]}
          type={type}
          onChange={handleChange}
          required
          size="small"
        />
      )
  }
}

export interface FormField {
  label: string,
  name: string,
  type: string,
  size: { xs?: number, md?: number },
  items?: { value: string, label: string }[],
}

export function Form<T>({name, fields, defaultValue, handleSubmit}: {name: string, fields: FormField[], defaultValue: Partial<T>, handleSubmit: (event: any, model: Partial<T>) => void}) {
  const initialState = defaultValue

  const [formState, setFormState] = React.useState(initialState)

  const handleChange = (event: any) => {
    const { name, value } = event.target
    setFormState({ ...formState, [name]: value })
  }

  const handleDateChange = (fieldName: any, newDate: any) => {
    setFormState({ ...formState, [fieldName]: newDate })
    console.log(fieldName)
    console.log(dayjs(newDate))
    console.log(formState)
  }

  return (
    <Grid container spacing={3}>
      { fields.map((field) => (
        <FormGrid key={field.name} size={field.size}>
          <FormLabel htmlFor={name} required>
          {field.label}
          </FormLabel>
          <FinsimInput
            formState={formState}
            name={field.name}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            type={field.type}
            items={field.items || []}
            />
        </FormGrid>
      ))}
      <FormGrid size={{ xs: 12 }}>
        <Box sx={{ display: 'flex', justifyContent: 'end', flexDirection: { sm: 'row' }}}>
          <Button variant="contained" onClick={(ev) => handleSubmit(ev, formState)}>
            Add {name}
          </Button>
        </Box>
      </FormGrid>
    </Grid>
  );
}
