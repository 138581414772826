import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { LineChart } from '@mui/x-charts/LineChart';
import { useGetExpensesQuery, useGetSavingsQuery } from '../services/Api'

function AreaGradient({ color, id }: { color: string; id: string }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.5} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}

// const savingsData = (expenses: any, savings: any, lookahead: number) => {
//   const totalSavings = savings.reduce((partialSum: number, a: any) => partialSum + Number(a.amount), 0)

//   return [...Array(lookahead)].fill(totalSavings);
// }

const extractDate = (date: Date) => {
  const month = date.toLocaleDateString('en-US', {
    month: 'short',
  });
  const day = date.getDate()
  return { month, day }
}

const formatDateX = (date: Date) => {
  const { month, day } = extractDate(date)
  return `${month} ${day}`
}

interface GraphHash {
  [date: string]: number
}

function getForecast(expenses: any, savings: any, lookahead: number) {
  const totalSavings = savings.reduce((partialSum: number, a: any) => partialSum + Number(a.amount), 0)
  const ys = [...Array(lookahead)].fill(totalSavings);

  let currentExpense = 0

  let expenseDiffs: GraphHash = {}
  for(const expense of expenses) {
    const startsAt = new Date(expense.starts_at)
    const endsAt = expense.ends_at === null ? null : new Date(expense.ends_at)

    const add = (date: Date) => {
      if(expenseDiffs[date.toDateString()] === undefined) {
        expenseDiffs[date.toDateString()] = 0
      }
      expenseDiffs[date.toDateString()] += Number(expense.amount)
    }

    switch(expense.recurrence) {
      case 'one_off':
        add(startsAt)
        break;
      case 'monthly':
        let date = startsAt
        let offsetDays = 0
        while (offsetDays < lookahead && (endsAt === null || date < endsAt)) {
          add(date)

          date.setMonth(date.getMonth() + 1);
          offsetDays += 30 // TODO: This is wrong, calculate the same day next month instead
        }
        break;
      default:
    }
  }

  const xs = [];
  let i = 0;
  let date = new Date((new Date()).toDateString());
  let data: GraphHash = {}
  let currentSavings = totalSavings

  let newYs = []
  while (i < lookahead) {
    if(expenseDiffs[date.toDateString()] !== undefined) {
      currentSavings -= expenseDiffs[date.toDateString()]
    }
    xs.push(formatDateX(date))
    newYs.push(currentSavings)
    i++;
    date.setDate(date.getDate() + 1);
  }

  return { xs, ys: newYs };
}

export default function SessionsChart() {
  const expenses = useGetExpensesQuery().data || []
  const savings = useGetSavingsQuery().data || []

  const theme = useTheme();
  const { xs, ys } = getForecast(expenses, savings, 90);

  const colorPalette = [
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.primary.dark,
  ];

  return (
    <Card variant="outlined" sx={{ width: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Savings
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: 'center', sm: 'flex-start' },
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              £175.500
            </Typography>
            <Chip size="small" color="success" label="+35%" />
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Total savings at any given time
          </Typography>
        </Stack>
        <LineChart
          colors={colorPalette}
          xAxis={[
            {
              scaleType: 'point',
              data: xs,
              tickInterval: (index, i) => (i + 1) % 5 === 0,
            },
          ]}
          series={[
            {
              id: 'direct',
              label: 'Direct',
              showMark: false,
              curve: 'linear',
              stack: 'total',
              area: true,
              stackOrder: 'ascending',
              data: ys,
            },
            // {
            //   id: 'referral',
            //   label: 'Referral',
            //   showMark: false,
            //   curve: 'linear',
            //   stack: 'total',
            //   area: true,
            //   stackOrder: 'ascending',
            //   data: [
            //     500, 900, 700, 1400, 1100, 1700, 2300, 2000, 2600, 2900, 2300, 3200,
            //     3500, 3800, 4100, 4400, 2900, 4700, 5000, 5300, 5600, 5900, 6200,
            //     6500, 5600, 6800, 7100, 7400, 7700, 8000,
            //   ],
            // },
            // {
            //   id: 'organic',
            //   label: 'Organic',
            //   showMark: false,
            //   curve: 'linear',
            //   stack: 'total',
            //   stackOrder: 'ascending',
            //   data: [
            //     1000, 1500, 1200, 1700, 1300, 2000, 2400, 2200, 2600, 2800, 2500,
            //     3000, 3400, 3700, 3200, 3900, 4100, 3500, 4300, 4500, 4000, 4700,
            //     5000, 5200, 4800, 5400, 5600, 5900, 6100, 6300,
            //   ],
            //   area: true,
            // },
          ]}
          height={250}
          margin={{ left: 50, right: 20, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          sx={{
            '& .MuiAreaElement-series-organic': {
              fill: "url('#organic')",
            },
            '& .MuiAreaElement-series-referral': {
              fill: "url('#referral')",
            },
            '& .MuiAreaElement-series-direct': {
              fill: "url('#direct')",
            },
          }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        >
          <AreaGradient color={theme.palette.primary.dark} id="organic" />
          <AreaGradient color={theme.palette.primary.main} id="referral" />
          <AreaGradient color={theme.palette.primary.light} id="direct" />
        </LineChart>
      </CardContent>
    </Card>
  );
}
