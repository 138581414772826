import * as React from 'react';
import { DataGrid, GridCellEditStopReasons, GridCellEditStopParams, MuiEvent, GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

export default function Table<T extends GridValidRowModel>({columns, data, onEdit}: {columns: GridColDef<T>[], data: readonly T[], onEdit: (model: T) => any}) {
  const handleProcessRowUpdateError = (p: any) => {
    console.error(p)
  }
  const processRowUpdate = React.useCallback(
    async (newRow: T) => {
      const response = await onEdit(newRow);
      return response.data;
    },
    [],
  );

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        pageSizeOptions={[50, 100, 200]}
        disableRowSelectionOnClick
      />
    </Box>
  );
}


