import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HighlightedCard from './HighlightedCard';
import SessionsChart from './SessionsChart';
import StatCard, { StatCardProps } from './StatCard';
import { useGetSavingsQuery, useUpdateSavingMutation, useCreateSavingMutation } from '../services/Api'
import { type Saving, columns as savingColumns, defaultValue as defaultSaving } from '../models/Saving'
import Table from './Table'
import Input from '@mui/material/Input';
import CreateButton from './CreateButton';

const createFormFields = [
  {
    label: 'Source',
    name: 'source',
    type: 'source',
    size: { xs: 12, md: 6 },
  },
  {
    label: 'Amount',
    name: 'amount',
    type: 'number',
    size: { xs: 12, md: 6 },
  },
]

export default function SavingsPage() {
  const { data, error, isLoading } = useGetSavingsQuery()
  const [createSaving, result] = useCreateSavingMutation()
  const [updateSaving, updateResult] = useUpdateSavingMutation()

  return isLoading ? (<><span>Loading</span></>) : (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Savings
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        <Grid size={{ sm: 12 }}>
          <Table onEdit={(row) => updateSaving(row)} columns={savingColumns} data={data || []}/>

        </Grid>
        <Grid alignItems="right" size={{ sm: 12 }}>
          <CreateButton name="Saving" create={createSaving} defaultModel={defaultSaving} createFormFields={createFormFields} />
        </Grid>
      </Grid>
    </Box>
  );
}
