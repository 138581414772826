import * as React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { useDeleteSavingMutation } from '../services/Api';

export interface Saving {
  id: number,
  source: string,
  amount: number,
}

export const defaultValue: Partial<Saving> = {
  source: '',
  amount: 0.0,
}

function DeleteButton({params}: any) {
  const { id } = params.row;
  const [deleteSaving, result] = useDeleteSavingMutation()

  return (
    <Button onClick={() => deleteSaving({id})}>Click</Button>
  )
}

export const columns: GridColDef<Saving>[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'source',
    headerName: 'Source',
    width: 150,
    editable: true,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 150,
    editable: true,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    renderCell: (params) => (
      <DeleteButton params={params} />
    )
  },
];

