import { configureStore } from '@reduxjs/toolkit'
import { api } from './services/Api'
import { setupListeners } from '@reduxjs/toolkit/query'
import authReducer from './features/authSlice'
import { persistStore } from 'redux-persist'
import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { logout } from './features/authSlice'

const unauthorizedMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const payload = (action.payload as { status: number })
      if (payload.status) {
        api.dispatch(logout())
      }
    }

    return next(action)
  }

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware()
  .prepend(unauthorizedMiddleware)
  .concat(api.middleware),
})

export const persistor = persistStore(store)

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
