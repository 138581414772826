import * as React from 'react';
import {
  RouterProvider,
} from "react-router-dom";
import { router } from './Routes';
import './App.css';
import { useSelector } from 'react-redux'
import { selectIsAuthenticated } from './features/authSlice'
import LoginPage from './components/LoginPage'

function LandingPage() {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  return isAuthenticated ? (
    <RouterProvider router={router} />
  ) : <LoginPage />
}

function App() {
  return (
    <React.StrictMode>
      <LandingPage />
    </React.StrictMode>
  )
  // return (<SideMenu />)
}

export default App;
