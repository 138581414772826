import * as React from 'react';
import { GridColDef, type GridRenderCellParams } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { useDeleteExpenseMutation } from '../services/Api';
import dayjs, { Dayjs } from 'dayjs';

export interface Expense {
  id: number,
  name: string,
  starts_at: Dayjs,
  ends_at: Dayjs | null,
  recurrence: string,
  amount: number,
  category: string,
}

export const defaultValue: Partial<Expense> = {
  name: '',
  amount: 0.0,
  starts_at: dayjs(),
  ends_at: null,
  recurrence: 'one_off'
}

function DeleteButton({params}: any) {
  const { id } = params.row;
  const [deleteExpense, _] = useDeleteExpenseMutation()

  return (
    <Button onClick={() => deleteExpense({id})}>Click</Button>
  )
}

export const columns: GridColDef<Expense>[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
    editable: true,
  },
  {
    field: 'starts_at',
    headerName: 'Starts At',
    width: 150,
  },
  {
    field: 'ends_at',
    headerName: 'Ends At',
    width: 150,
  },
  {
    field: 'recurrence',
    headerName: 'Recurrence',
    width: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 150,
    editable: true,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 150,
    editable: true,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, Date>) => (
      <DeleteButton params={params} />
    )
  },
];

