import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import { type MonzoIntegration, useGetMonzoStatusQuery, useConnectMonzoMutation, useRemoveMonzoMutation } from '../services/Api'
import Stack from '@mui/material/Stack';

function LogoutMonzoButton() {
  const [logoutMonzo, {}] = useRemoveMonzoMutation()

  const openMonzoPage = async () => {
    await logoutMonzo();
  }

  return (
    <Button onClick={openMonzoPage} variant="contained" size="small" fullWidth>
      Remove Monzo
    </Button>
  )
}

function ConnectMonzoButton() {
  const [connectMonzo, {}] = useConnectMonzoMutation()

  const openMonzoPage = async () => {
    const { data: { url: url } } = await connectMonzo();
    window.location.href = url;
  }

  return (
    <Button onClick={openMonzoPage} variant="contained" size="small" fullWidth>
      Connect Monzo
    </Button>
  )
}

function MonzoCard({ message, show_cta = true, show_sign_out = false }: { message: string, show_cta?: boolean, show_sign_out?: boolean }) {
  return (
    <CardContent>
      <Stack direction="column" sx={{
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}>
        <AutoAwesomeRoundedIcon fontSize="small" />
        <Typography variant="body1" sx={{ fontWeight: 900 }}>
          Monzo
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
          {message}
        </Typography>
        { show_cta && <ConnectMonzoButton /> }
        { show_sign_out && <LogoutMonzoButton /> }
      </Stack>
    </CardContent>
  )
}

function MonzoCardCompleted({monzoAuthentication}: {monzoAuthentication: MonzoIntegration}) {
  const message = `Connected as ${monzoAuthentication.monzo_user_id}`

  return <MonzoCard message={message} show_cta={false} show_sign_out={true} />
}

function MonzoAuthenticationCard({monzoAuthentication}: {monzoAuthentication: MonzoIntegration}) {
  const date = (new Date(monzoAuthentication.expires_at)).toString()

  const monzoCard = () => {
    switch(monzoAuthentication.status) {
      case "initiated":
        return (<MonzoCard message={`Integration initiated`} show_cta={false}/>)
      case "authorized":
        return (<MonzoCard message={`In Progress (Approve from app)`} show_cta={false}/>)
      case "completed":
        return (<MonzoCardCompleted monzoAuthentication={monzoAuthentication} />)
      case "errored":
        switch(monzoAuthentication.error_reason) {
          case "verification_required":
            return (<MonzoCard message={`Could not get an approval within 5 minutes`}/>)
          case "insufficient_permissions":
            return (<MonzoCard message={`Could not get an approval within 5 minutes`}/>)
          default:
            return (<MonzoCard message={`Unknown error reason: ${monzoAuthentication.error_reason}`}/>)
        }
      default:
        return (<MonzoCard message={`Unknown status: ${monzoAuthentication.status}`}/>)
    }
  }

  return monzoCard()
}

function MonzoAuthenticationCta() {
  return (
    <CardContent>
      <AutoAwesomeRoundedIcon fontSize="small" />
      <Typography gutterBottom sx={{ fontWeight: 600 }}>
        Plan about to expire
      </Typography>
      <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
        Enjoy 10% off when renewing your plan today.
      </Typography>
      <ConnectMonzoButton />
    </CardContent>
  )
}

export default function CardAlert() {
  const { data: monzoAuthentication, isLoading } = useGetMonzoStatusQuery()

  return (
    <Card variant="outlined" sx={{ m: 1.5, p: 1.5 }}>
      { monzoAuthentication ? <MonzoAuthenticationCard monzoAuthentication={monzoAuthentication} /> : (<MonzoAuthenticationCta />) }
    </Card>
  );
}
