import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddCircle from '@mui/icons-material/AddCircle';
import TransitionsModal from './TransitionsModal';
import Button from '@mui/material/Button';
import { type FormField, Form } from './Form';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '2px',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function CreateButton<T>(
  {name, defaultModel, createFormFields, create}: {
    name: string,
    defaultModel: Partial<T>,
    createFormFields: FormField[],
    create: any
  }) {
  return (
    <TransitionsModal component={(openModal: any) => (
      <Button onClick={openModal} variant="contained" size="small" component="label" startIcon={<AddCircle />}>
        Create
      </Button>
    )} content={(closeModal: any) => (
      <Box sx={style}>
        <Typography id="transition-modal-title" variant="h6" component="h2">
          New {name}
        </Typography>
        <Form name={name} defaultValue={defaultModel} fields={createFormFields} handleSubmit={(_, model: any) => {
          create(model);
          closeModal();
        }}/>
      </Box>
    )} />
  )
}
