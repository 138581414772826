import * as React from 'react';
import logo from './logo.svg';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SideMenu from './SideMenu';
import HomePage from './HomePage';
import ExpensesPage from './ExpensesPage';
import SavingsPage from './SavingsPage';
import Header from './Header';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';

function RenderPage({selectedPage}: {selectedPage: string}) {
  switch(selectedPage) {
    case 'Expenses':
      return (<ExpensesPage />)
    case 'Savings':
      return (<SavingsPage />)
    default:
      return (<HomePage />)
  }
}

export default function Page({selectedPage}: {selectedPage: string}) {
  return (
    <Box sx={{ display: 'flex' }}>
      <SideMenu selectedPage={selectedPage}/>
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          backgroundColor: alpha(theme.palette.background.default, 1),
          overflow: 'auto',
        })}
      >
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            mx: 3,
            pb: 5,
            mt: { xs: 8, md: 0 },
          }}
        >
          <Header selectedPage={selectedPage} />
          <RenderPage selectedPage={selectedPage} />
        </Stack>
      </Box>
    </Box>
  )
}
